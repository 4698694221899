<template lang="pug">
.orderlist.flex.column
  .status-header.flex.align-center
    .item.flex.column.justify-center.align-center(v-for="(value, key) in statistics")
      .num {{ value }}
      .text
        template(v-if="key === 'in'") 在場
        template(v-if="key === 'leaved'") 離場
        template(v-if="key === 'paused'") 已暫停
        template(v-if="key === 'cancel'") 已取消
        template(v-if="key === 'should_leave'") 應離場
  .list.flex.column.gap-8
    .flex.align-center.justify-stretch.p-4
      dd-button.fill(@click.native="goPage('/newFishOrder')") 開單
    template(v-for="(item, index) in reserves")
      .list-item.flex.align-center.p-4.gap-20.clickable(
        :class="{'highlight': item.shouldLeave}"
        @click="show(item)")
        zoomable-image(
          v-if="item.thumb" :thumb="item.thumb", :src="item.image"
          width="80px", height="80px")
        zoomable-image(v-else thumb='/user.png', width="80px", height="80px")
        .info.flex.justify-center.align-center.fill
          .info.flex.column.align-start.gap-8.fill
            span 訂單編號 {{ item.orderNumber.toUpperCase() }}
            span {{ item.startTime }} - {{item.endTime}}
            span(v-if="item.firstNote") {{ item.firstNote }}
            .status.flex.align-center.gap-4
              .pay-status {{ item.payStatusText }}
              .splitter /
              .currentStatus {{ item.statusText }}
      md-divider
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { getReserves } from '@/api/reserve';
import { getImageThumbPath, getImagePath } from '@/api/image';
import constant from '@/data/constant';

export default {
  name: 'OrderList',
  data() {
    return {
      reserves: [],
      statistics: {},
      now: parseInt(new Date().getTime() / 1000, 10),
    };
  },
  computed: {
    ...mapGetters(['showMenu', 'headerTitle']),
  },
  methods: {
    ...mapMutations(['setHeaderTitle']),
    goPage(path) {
      this.showSideMenu = false;
      this.$router.push(path);
    },
    show(item) {
      console.log('show', item);
      this.goPage(`/order/${item.uuid}`);
    },
    async loadReserves(start, end) {
      const now = parseInt(new Date().getTime() / 1000, 10);
      const data = await getReserves(start, end);
      const reserves = (data?.data?.data || []).map((r) => ({
        ...r,
        startTime: this.$timeToString(r.start * 1000),
        endTime: this.$timeToString(r.end * 1000),
        orderNumber: r.uuid.slice(0, 8),
        image: getImagePath((r.notes || [])[0]?.photo_id),
        thumb: getImageThumbPath((r.notes || [])[0]?.photo_id),
        firstNote: (r.notes || [])[0]?.message || '',
        shouldLeave: r.end < now,
        statusText: constant.orderStatusToText(r.status, r.end < this.now),
        payStatusText: constant.paidStatusToText(r.payment),
      }));
      reserves.sort((r1, r2) => {
        if (r1.end < now && r2.end > now) {
          return -1;
        }
        if (r1.end > now && r2.end < now) {
          return 1;
        }
        if (r1.status !== r2.status) {
          return r1.status - r2.status;
        }
        return r1.end - r2.end;
      });
      this.reserves = reserves;
      this.statistics = data?.data?.statistics || {};
    },
    async loadData() {
      const now = new Date();
      const start = new Date(now).setHours(0, 0, 0, 0, 0);
      const end = new Date(now).setHours(23, 59, 59, 999);
      await this.$execWithLoading(async () => {
        const s = parseInt(start / 1000, 10);
        const e = parseInt(end / 1000, 10);
        await this.loadReserves(s, e);
      });
    },
  },
  mounted() {
    this.setHeaderTitle('訂單列表');
    this.loadData();
  },
};
</script>
<style lang="scss" scoped>

.status-header {
  background: rgb(138, 231, 138);
  width: 100%;
  flex: 0 0 auto;
  justify-content: space-around;
  padding: 10px;

  .item{
    width: 60px;
    height: 60px;
    background-color: #eee;
    border-radius: 30%;
    padding: 4px;
    .num{
      font-size: 20px;
      font-weight: bold;
    }
    .text{
      font-size: 16px;
    }
  }
}
.orderlist{
  overflow: hidden;
  .fab-fish, .fab-food{
    z-index: 5;
    bottom: 8px;
    right: 16px;
  }
  .fab-food{
    right: 88px;
    left: auto;
  }
  .status-header{
    flex: 0 0 auto;
  }
  .list{
    flex: 1;
    overflow: auto;
    overflow: overlay;
    .list-item {
      min-height: 88px;
      &.highlight {
        background: lighten(#F42C04, 40%);
      }
    }
  }
}

</style>
