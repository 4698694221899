import instance from './base';

export async function uploadImage(file) {
  const formData = new FormData();
  formData.append('file', file);
  const rsp = await instance.post('/image/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return rsp.data;
}

let baseURL = 'http://localhost:8000';
if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://pos.bearathome.com.tw/api';
}
export function getImagePath(id) {
  return id ? `${baseURL}/image/${id}` : '';
}

export function getImageThumbPath(id) {
  return id ? `${baseURL}/image/${id}/thumb` : '';
}
